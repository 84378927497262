<template>
  <Registration
    :registrationForm="getUSER"
    :show-checking="showSMSchecking"
    :show-modal="showModal"
    :isConfirmed="get_IS_CONFIRMED_REGISTRATION"
    v-model="timer"
    @regData="saveRegData"
    @verified="verified"
    @changeScreen="SET_CONFIRMED_REGISTRATION(true)"
    @sendAgain="sendAgain"
    @logout="LOGOUT"
  />
</template>

<script>
import Registration from "@/components/pages/Registration";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "RegistrationView",
  components: { Registration },
  data() {
    return {
      timer: false,
      phone: "",
      showSMSchecking: false,
      showModal: false,
      loading: true,
    };
  },
  computed: {
    ...mapGetters("errorModule", ["getERROR"]),
    ...mapGetters("AuthModule", ["getUSER"]),
    ...mapGetters("regModule", ["get_IS_CONFIRMED_REGISTRATION"]),
  },
  methods: {
    ...mapActions("regModule", [
      "REG_NEW_USER",
      "SAVE_NEW_USER",
      "SET_CONFIRMED_REGISTRATION",
      "CHECK_REG_PARE",
    ]),
    ...mapActions("smsModule", ["REQUEST_SMS", "CONFIRM_SMS"]),
    ...mapActions("AuthModule", ["LOGOUT"]),
    saveRegData(data) {
      if (!this.timer) {
        if (typeof data.postcodeactualAddress != 'undefined') data.postcodeActualAddress = data.postcodeactualAddress;
        if (typeof data.postcoderegistrationAddress != 'undefined') data.postcodeRegistrationAddress = data.postcoderegistrationAddress;
        this.SAVE_NEW_USER(data);
        this.phone = data.phone;
        this.CHECK_REG_PARE({
          email: data.email,
          phone: data.phone,
          birthday: data.birthday,
        }).then((response) => {
          if (response) {
            this.REQUEST_SMS({ phone: this.phone, new_user: true, action: 'registration' }).then(
              (success) => {
                if (success) {
                  this.showSMSchecking = true;
                  this.timer = true;
                } else this.getERROR ? this.$toast.error(this.getERROR) : "";
              }
            );
          } else {
            this.getERROR ? this.$toast.error(this.getERROR) : "";
          }
        });
      }
    },
    verified(code) {
      this.CONFIRM_SMS({ phone: this.phone.replace("+", ""), key: code }).then(
        (success) => {
          if (success) {
            this.REG_NEW_USER().then((resp) => {
              if (resp.success) {
                this.loading = false;
                this.showModal = true;
              } else this.$toast.error(`${this.getERROR}`);
            });
          } else {
            this.$toast.error(`${this.getERROR}`);
          }
        }
      );
    },
    sendAgain() {
      if (!this.timer) {
        this.phone = this.getUSER.phone.trim();
        this.REQUEST_SMS({ phone: this.phone, new_user: true, action: 'registration' }).then(
          (success) => {
            if (success) {
              this.timer = true;
            }
          }
        );
      }
    },
  },
  mounted() {
    if (JSON.stringify(this.getUSER) === "{}") this.SAVE_NEW_USER();
  },
};
</script>

<style scoped lang="scss"></style>
