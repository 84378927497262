<template>
  <authorization-wrapper>
    <div class="reg-container__content-form">
      <transition mode="out-in" name="fade">
        <div v-if="!isConfirmRegistration && !showModal" class="reg-form-container">
          <h1>Регистрация</h1>
          <div class="login-by-gov-service">
            <rwm-button outlined width="100%" @click="GoToESIA()">Зарегистрироваться через Госуслуги
              <template v-slot:prepend-img>
                <img alt="gususlugi_icon" src="@/assets/gususlugi_icon.png">
              </template>
            </rwm-button>
            <rwm-question-mark
                class="login-by-gov-service__mark"
                style="z-index: 21"
                text="Если у Вас есть подтвержденная учетная запись на Госуслугах, пожалуйста, авторизуйтесь на сайте Госуслуг и перейдите в ЛК для дальнейшей регистрации"
            />
          </div>
          <div class="login-by-gov-service" style="margin-bottom: 160px">
            <rwm-button outlined width="100%" @click="isConfirmRegistration=true">Зарегистрироваться по паспортным
              данным
            </rwm-button>
            <rwm-question-mark
                class="login-by-gov-service__mark"
                style="z-index: 20"
                text="Перейдите на форму регистрации и заполните данные"/>
          </div>
        </div>
        <div v-else-if="isConfirmRegistration && !showModal" class="form-container">
          <h1>Регистрация</h1>
          <h3>Все поля обязательны для заполнения</h3>
          <div class="form-container__form">
            <div class="form-container__form__fio">
              <div class="col-3">
                <rwm-text-field
                    v-model="registrationForm.userName.sname"
                    :error="errors.sname"
                    label="Фамилия"
                    @blur="validate('sname')"
                    :disabled="isEdit"
                />
              </div>
              <div class="col-3">
                <rwm-text-field
                    v-model="registrationForm.userName.name"
                    :error="errors.name"
                    label="Имя"
                    @blur="validate('name')"
                    :disabled="isEdit"
                />
              </div>
              <div class="col-3">
                <rwm-text-field
                    v-model="registrationForm.userName.mname"
                    :error="errors.mname"
                    label="Отчество"
                    @blur="validate('mname')"
                    :disabled="isEdit"
                />
              </div>
            </div>
            <div class="form-container__form__data">
              <div class="col-3">
                <rwm-text-field
                    v-model="registrationForm.phone"
                    :error="errors.phone"
                    label="Телефон – формат +7 ХХХ ХХХ ХХ ХХ"
                    type="phone"
                    @blur="validate('phone')"
                    v-mask="'+7 ### ### ## ##'"
                    @focus="registrationForm.phone === null || registrationForm.phone.length < 2 ? registrationForm.phone = '+7 ' : ''"
                />
              </div>
              <div class="col-3">
                <rwm-text-field
                    v-model="registrationForm.email"
                    :error="errors.email"
                    label="email@email.com"
                    type="email"
                    @blur="validate('email')"
                />
              </div>
              <div class="col-3">
                <rwm-date-picker v-model="registrationForm.birthday" :error="errors.birthday" label="Дата рождения"
                                 @blur="validate('birthday')" @isError="isDateError"/>
              </div>
              <div class="col-3">
                <rwm-text-field
                    v-model="registrationForm.birthplace"
                    :error="errors.birthplace"
                    label="Место рождения - город"
                    @blur="validate('birthplace')"
                />
              </div>
            </div>
            <div class="form-container__form__data">
              <div class="col-9">
                <rwm-autocomplete
                        label="Адрес регистрации – город, улица, дом, квартира"
                        :error="errors.registrationAddress"
                        :list="addressList.reg"
                        @input="findList"
                        @select="selectAddress"
                        :value="registrationForm.registrationAddress"
                        @blur="onAddressBlur('registrationAddress', ...arguments)"
                />
              </div>
              <div class="col-3">
                <rwm-text-field label="Почтовый индекс"
                                v-model="registrationForm.postcoderegistrationAddress"
                                v-mask="'######'"
                                :error="errors.postcoderegistrationAddress"
                                @blur="onAddressBlur('postcoderegistrationAddress', ...arguments)" />
              </div>
            </div>
            <div class="form-container__form__data">
              <div class="form-container__form__data__checkbox" style="margin-bottom: 12px">
                <rwm-checkbox
                    v-model="registrationForm.booleanAgreements.isRegNaturalAddress.value"
                    label="Совпадает с адресом проживания"
                    @change="isSameAddress(registrationForm.booleanAgreements.isRegNaturalAddress.value)"
                />
              </div>
            </div>
            <div class="form-container__form__data">
              <div class="col-9">
                <rwm-autocomplete
                        label="Адрес проживания – город, улица, дом, квартира"
                        :error="errors.actualAddress"
                        :list="addressList.actual"
                        @input="findSecList"
                        @select="selectSecAddress"
                        :value="registrationForm.actualAddress"
                        @blur="onAddressBlur('actualAddress', ...arguments)"
                        :disabled="registrationForm.booleanAgreements.isRegNaturalAddress.value"
                />
              </div>
              <div class="col-3">
                <rwm-text-field label="Почтовый индекс"
                                v-model="registrationForm.postcodeactualAddress"
                                v-mask="'######'"
                                :error="errors.postcodeactualAddress"
                                @blur="onAddressBlur('postcodeactualAddress', ...arguments)"
                                :disabled="registrationForm.booleanAgreements.isRegNaturalAddress.value"/>
              </div>
            </div>
            <div class="form-container__form__checkbox-group">
              <div v-for="agreement in registrationForm.booleanAgreements" :key="agreement.label"
                   class="form-container__form__checkbox-group__checkbox">
                <rwm-checkbox
                    v-if="agreement.label !== 'Совпадает с адресом проживания'"
                    v-model="agreement.value"
                    :label="agreement.label"
                    :error="valAgreements(agreement)"
                />
              </div>
            </div>
            <div class="form-container__form__password-section">
              <div class="col-3">
                <rwm-text-field
                    v-model="registrationForm.password"
                    :error="errors.password"
                    :type="isOpenPass ? 'text' : 'password'"
                    label="Придумайте пароль"
                    @blur="valPassword('password')"
                    @onAppend="isOpenPass=!isOpenPass"
                >
                  <div slot="append-icon">
                    <transition name="fade">
                      <v-icon v-if="isOpenPass" @click="isOpenPass=!isOpenPass">view</v-icon>
                      <v-icon v-else @click="isOpenPass=!isOpenPass">close</v-icon>
                    </transition>
                  </div>
                </rwm-text-field>
              </div>
              <div class="col">
                <p>Пароль должен быть длиной не менее 8 символов, содержать цифры, буквы латиницей верхнего и нижнего регистра,
                  а также спецсимволы: !, @, #, $, %, ^, &, *, _</p>
              </div>
              <div class="col-3">
                <rwm-text-field
                    v-model="registrationForm.passwordRepeat"
                    :error="errors.isPasswordConfirm"
                    :type="isOpenConfPass ? 'text' : 'password'"
                    label="Повторите пароль"
                    @blur="valPassword('password-re')"
                    @onAppend="isOpenConfPass=!isOpenConfPass"
                >
                  <div slot="append-icon">
                    <transition name="fade">
                      <v-icon v-if="isOpenConfPass" @click="isOpenConfPass=!isOpenConfPass">view</v-icon>
                      <v-icon v-else @click="isOpenConfPass=!isOpenConfPass">close</v-icon>
                    </transition>
                  </div>
                </rwm-text-field>
              </div>
              <div class="col">
              </div>
              <div class="col-3">
                <rwm-button width="100%" @click="validateForm()" :disabled="timer">Подписать</rwm-button>
                <rwm-sms-checking
                        v-model="_timer"
                        :time="60"
                        field-width="150"
                        @sendAgain="sendAgain()"
                        @verified="codeIsVerified"
                        :isShow="showChecking"
                />
                <p class="sms-login-info">После подтверждения телефона на указанный E-mail будет отправлена ссылка для
                  подтверждения E-mail</p>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="showModal" class="form-container">
          <welcome :name="`${registrationForm.userName.name} ${registrationForm.userName.mname}`" status="phone" to="/login" @logout="$emit('logout')"/>
        </div>
      </transition>
    </div>
  </authorization-wrapper>
</template>

<script>
import AuthorizationWrapper from "@/components/modules/AuthorizationWrapper";
import RwmButton from "@/components/RWMButton/RwmButton";
import RwmQuestionMark from "@/components/RWMQutestionMark/RwmQuestionMark";
import RwmTextField from "@/components/RWMTextField/RwmTextField";
import RwmCheckbox from "@/components/RWMCheckbox/RwmCheckbox";
import VIcon from "@/components/icons/VIcon";
import RwmSmsChecking from "@/components/RWMSMSChecking/RwmSmsChecking";
import RwmDatePicker from "@/components/RWMDatePicker/RwmDatePicker";
import Welcome from "../modals/Welcome";
import { mapActions } from "vuex"

import {validateParam, validatePassword} from "../../models/Validation";
import RwmAutocomplete from "../RWMAutocomplete/RwmAutocomplete";

export default {
  name: "Registration",
  components: {
    RwmAutocomplete,
    Welcome,
    RwmDatePicker,
    RwmSmsChecking,
    VIcon, RwmCheckbox, RwmTextField, RwmQuestionMark, RwmButton, AuthorizationWrapper
  },
  props: {
    showChecking: {
      type: Boolean,
      required: true
    },
    timer: Boolean,
    showModal: Boolean,
    isConfirmed: Boolean,
    registrationForm: {
      type: Object,
      default: () => ({
          userName: {
            name: '',
            sname: '',
            mname: '',
          },
          phone: '',
          email: '',
          birthday: '',
          birthplace: '',
          actualAddress: '',
          registrationAddress: "",
          registrationMethod: 0,
          inn: '',
          password: '',
          passwordRepeat: '',
          booleanAgreements: {
            isRegNaturalAddress: {
              label: "Совпадает с адресом проживания",
              value: false,
            },
            isElectronicDocumentManagement: {
              label: "Присоединяюсь к <a href='/client/v1/files/get?type=100'>соглашению об электронном документообороте</a> с УК РВМ Капитал",
              value: false
            },
            isPersonalDataProcessing: {
              label: "Даю <a href='/client/v1/files/get?type=100' target='_blank'>согласие на обработку персональных данных</a>",
              value: false
            },
            isResident: {
              label: "Не являюсь налоговым резидентом Соединенных Штатов Америки",
              value: false
            },
            isOfficialThisRelative: {
              label: "Не являюсь публичным должностным лицом, супругом(ой) или близким родственником такого лица",
              value: false
            },
            isOwnPhone: {
              label: "Подтверждаю, что являюсь фактическим пользователем абонентского номера",
              value: false
            }
          }
        })
    }
  },
  model: {
    prop: 'timer',
    event: 'timer'
  },
  computed: {
    isEdit() {
      return this.registrationForm.registrationMethod >= 3
    },
    _timer: {
      get() {
        return this.timer
      },
      set(val) {
        this.$emit('timer', val)
      }
    },
    isConfirmRegistration: {
      get() {
        return this.isConfirmed;
      },
      set(newVal) {
        this.$emit('changeScreen', newVal)
      }
    }
  },
  data() {
    return {
      addressList: {
        reg: [],
        actual: []
      },
      isOpenPass: false,
      isOpenConfPass: false,
      errors: {
        name: false,
        sname: false,
        mname: false,
        phone: false,
        email: false,
        birthday: false,
        birthplace: false,
        actualAddress: false,
        postcodeactualAddress: false,
        registrationAddress: false,
        postcoderegistrationAddress: false,
        password: false,
        // booleanAgreements
        isPasswordConfirm: false,
        isElectronicDocumentManagement: false,
        isOfficialThisRelative: false,
        isOwnPhone: false,
        isPersonalDataProcessing: false,
        isResident:false,
      },
    }
  },
  methods: {
    ...mapActions("autocompleteModule", ["AUTOCOMPLETE_ADDRESS"]),
    valAgreements(item) {
      let currentKey
      for (let key in this.registrationForm.booleanAgreements) {
        if (item.label === this.registrationForm.booleanAgreements[key].label) {
          currentKey = key
          break
        }
      }
      return this.errors[currentKey]
    },
    findList(value) {
      if (value?.length > 3) {
        const tis = this;
        tis.AUTOCOMPLETE_ADDRESS({address: value}).then(resp => {
          if (typeof resp != 'undefined' && Array.isArray(resp)) {
            tis.addressList.reg = resp.map(item => {
              if (typeof item.address != "undefined") {
                item.value = item.address;
                delete item.address;
              }
              return item;
            })
          }
          else if (typeof resp != 'undefined' && typeof resp.address != 'undefined') {
            resp.value = resp.address;
            delete resp.address;
            tis.addressList.reg = [resp];
          }
          else
            tis.addressList.reg = resp;
        })
      }
    },
    findSecList(value) {
      if (value?.length > 3) {
        const tis = this;
        tis.AUTOCOMPLETE_ADDRESS({address: value}).then(resp => {
          if (typeof resp != 'undefined' && Array.isArray(resp)) {
            tis.addressList.actual = resp.map(item => {
              if (typeof item.address != "undefined") {
                item.value = item.address;
                delete item.address;
              }
              return item;
            })
          }
          else if (typeof resp != 'undefined' && typeof resp.address != 'undefined') {
            resp.value = resp.address;
            delete resp.address;
            tis.addressList.actual = [resp];
          }
          else
            tis.addressList.actual = resp;
        })
      }
    },
    onAddressBlur(param, event) {
      this.registrationForm[param] = event.target.value
      if (this.registrationForm.registrationAddress == null || this.registrationForm.registrationAddress === '') {
        this.registrationForm.postcoderegistrationAddress = ''
      }
      if (this.registrationForm.actualAddress == null || this.registrationForm.actualAddress === '') {
        this.registrationForm.postcodeactualAddress = ''
      }
      if (param === "registrationAddress" || param === "postcoderegistrationAddress")
        this.isSameAddress(this.registrationForm.booleanAgreements.isRegNaturalAddress.value)
      this.validate(param)
    },
    selectSecAddress(input) {
      let address = this.addressList.actual.filter(el => el===input)
      this.registrationForm.actualAddress = address[0].value
      this.registrationForm.postcodeactualAddress = address[0].postcode
      this.validate(`postcodeactualAddress`);
      this.validate(`actualAddress`);
      if (address[0].postcode == null) {
        this.registrationForm.postcodeactualAddress = "";
        this.$toast.error('Извините, индекс не найден. Пожалуйста, заполните поле "Почтовый индекс" вручную.');
      }
    },

    selectAddress(input) {
      let address = this.addressList.reg.filter(el => el===input)
      this.registrationForm.registrationAddress = address[0].value
      this.registrationForm.postcoderegistrationAddress = address[0].postcode
      this.validate(`postcoderegistrationAddress`);
      this.validate(`registrationAddress`);
      if (address[0].postcode == null) {
        this.registrationForm.postcoderegistrationAddress = "";
        this.$toast.error('Извините, индекс не найден. Пожалуйста, заполните поле "Почтовый индекс" вручную.');
      }
      this.isSameAddress(this.registrationForm.booleanAgreements.isRegNaturalAddress.value)
    },
    GoToESIA() {
      window.location.href = `${process.env.VUE_APP_API_URL}esia/oauth/login`
    },
    isDateError(err) {
      this.errors.birthday = err
    },
    codeIsVerified(code) {
      this.$emit('verified', code)
    },
    sendAgain() {
      this.$emit('sendAgain')
    },
    isSameAddress(value) {
      if (value) {
        this.registrationForm.actualAddress = this.registrationForm.registrationAddress
        this.registrationForm.postcodeactualAddress = this.registrationForm.postcoderegistrationAddress
        this.validate("actualAddress")
        this.validate("postcodeactualAddress")
      }
    },
    validate(param) {
      const keys = Object.keys(this.errors)
      if (keys.some((item) => item === param)) this.errors[param] = !validateParam(this.registrationForm, param).success
    },
    valPassword(param) {
      if (param === "password") {
        let isValid=validatePassword(this.registrationForm[param])
        this.errors[param] = !isValid.success
        if (!isValid.success) this.$toast.error(isValid.error)
      } else if (!this.errors.password) {
        if (this.registrationForm.password === this.registrationForm.passwordRepeat) {
          this.errors.isPasswordConfirm = false
        } else {
          this.errors.isPasswordConfirm = true;
          this.$toast.error("Проверьте правильность пароля")
        }
      } else {
        this.errors.isPasswordConfirm = true
      }
    },
    validateForm() {
      let allFieldsIsValid = false
      let allFieldsChack = false

      let keys = Object.keys(this.registrationForm)
      keys.forEach(item => {
        if (item.match('userName')) {
          let usname = Object.keys(this.registrationForm.userName)
          usname.forEach(name => {
            this.validate(name)
          })
        } else {
          this.validate(item)
        }
        if (item.match('booleanAgreements')) {
          for (let bool in this.registrationForm.booleanAgreements) {
            if (bool !== "isRegNaturalAddress") {
              this.errors[bool] = !this.registrationForm.booleanAgreements[bool].value
            }
          }
        }
      })
      this.valPassword("password")
      for (let err in this.errors) {
        allFieldsChack = this.errors[err];
        if(this.errors[err]) break
      }
      allFieldsIsValid = !allFieldsChack
      if (allFieldsIsValid) {
        this.$emit('regData', this.registrationForm)
      }
    }
  },
  mounted() {
    sessionStorage.getItem('user') && sessionStorage.getItem('user') !== "{}" ? this.registrationForm = JSON.parse(sessionStorage.getItem('user')) : ''
  }
}
</script>

<style lang="scss" scoped>
.reg-container__content-form {
  flex: 1 1 auto;
  padding: 0 42px;
  margin-bottom: 60px;
  text-align: left;
  @media screen and (min-width: 961px) {
    padding: 0 40px;
  }

  & .form-container {
    max-width: 1460px;
    padding: 0;
  }

  & .reg-form-container {
    max-width: 516px;
  }

  & .form-container,
  & .reg-form-container {
    margin: auto;
    padding-top: 44px;

    & h1 {
      margin-bottom: 24px;
    }

    & .login-by-gov-service {
      margin-top: 44px;
      position: relative;

      &__mark {
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        right: -50px;
      }
    }

    &__form {
      margin-top: 32px;

      &__fio,
      &__data,
      &__password-section {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        column-gap: 44px;
        margin-top: 24px;
        row-gap: 24px;

        & .col {
          flex: 1 1 auto;
        }

        & .col-3 {
          min-width: 250px;
          flex: 1 1 calc((100% / 3) - 2rem);
          @media screen and (min-width: 961px) {
            flex: 0 0 calc((100% / 3) - 2rem);
          }
        }

        & .col-12 {
          flex: 1 1 100%;
        }

        &__checkbox {
          margin-bottom: 25px;
        }

        & .sms-login-info {
          font-weight: 500;
          font-size: 20px;
          line-height: 27px;
          color: $RWM-grey;
          margin-top: 24px;
        }
      }

      &__checkbox-group {
        margin-bottom: 54px;

        &__checkbox {
          display: block;
          margin-top: 22px;
          padding: 1px 0;
        }
      }

      &__password-section {
        text-align: left;
        font-weight: 500;
        font-size: 20px;
        line-height: 27px;
        color: $RWM-grey;
        flex-direction: column;
        @media screen and (min-width: 961px) {
          flex-direction: row;
        }

        & .col {
          flex: 0 1 calc(100% - 100% / 3 - 2rem)
        }
      }
    }
  }
}

.sms-login {
  margin-top: 40px;
}
</style>
